<template>
  <v-snackbar
    v-model="isDisplay"
    :vertical="true"
    :timeout="timeout"
  >
    {{ text }}

    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="isDisplay = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      isDisplay: false,
      text: '',
      color: '',
      timeout: 2000
    }
  },
  created() {
    const self = this
    this.$bus.$on('successMessage', (msg) => {
      self.showSnackbar(msg)
    })
  },
  methods: {
    showSnackbar(msg) {
      this.isDisplay = true
      this.text = msg
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
